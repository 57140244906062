exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../../../src/templates/EventPage.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-events-page-tsx": () => import("./../../../src/templates/EventsPage.tsx" /* webpackChunkName: "component---src-templates-events-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-newsletter-page-tsx": () => import("./../../../src/templates/NewsletterPage.tsx" /* webpackChunkName: "component---src-templates-newsletter-page-tsx" */),
  "component---src-templates-profile-page-tsx": () => import("./../../../src/templates/ProfilePage.tsx" /* webpackChunkName: "component---src-templates-profile-page-tsx" */)
}

