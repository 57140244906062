import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

export type ILocale = 'en' | 'es' | 'zh'
interface IContext {
  locale: ILocale
  setLocale: Dispatch<SetStateAction<ILocale>>
}

const LocaleContext = createContext<IContext | undefined>(undefined)

const useLocaleContext = () => {
  return useContext(LocaleContext) as IContext
}

export const LocaleContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [locale, setLocale] = useState<ILocale>('en')
  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: value => setLocale(value),
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export default useLocaleContext
